import {useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";
import {matchService} from "../../services/api/MatchService";
import {authService} from "../../services/api/AuthService";

export const MyMatchButton = () => {
    const navigate = useNavigate();

    const [matchCount, setMatchCount] = useState(0);

    useEffect(() => {

        if (authService.isLogged()) {
            const fetchNbMatch = async () => {
                try {
                    const response = await matchService.getNbMatch();
                    setMatchCount(response.data);
                } catch (error) {
                    console.error("Erreur lors de la récupération des matchs en attente", error);
                }
            };
            fetchNbMatch();
        }
    }, []);


    return (
        <button data-tooltip-target="tooltip-home"
                type="button"
                className="inline-flex flex-col items-center justify-center px-5 hover:bg-gray-50 dark:hover:bg-gray-800 group"
                data-tooltip-id="tooltip-home"
                data-tooltip-content="Retour à l'acceuil"
                data-tooltip-place="right"
                onClick={() => navigate("/mymatch")}
        >
            <div className="relative inline-flex flex-row items-start px-5 hover:bg-gray-50 dark:hover:bg-gray-800 group h-full">
                <svg className="w-5 h-5 text-gray-500 dark:text-gray-400 group-hover:text-blue-600 dark:group-hover:text-blue-500 ml-auto mr-auto mt-auto mb-auto"
                     aria-hidden="true" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                    <path
                        d="M349.4 44.6c5.9-13.7 1.5-29.7-10.6-38.5s-28.6-8-39.9 1.8l-256 224c-10 8.8-13.6 22.9-8.9 35.3S50.7 288 64 288H175.5L98.6 467.4c-5.9 13.7-1.5 29.7 10.6 38.5s28.6 8 39.9-1.8l256-224c10-8.8 13.6-22.9 8.9-35.3s-16.6-20.7-30-20.7H272.5L349.4 44.6z"/>
                </svg>
                {authService.isLogged() &&
                    <span
                        className="absolute top-0 right-0 inline-flex items-center justify-center w-6 h-6 text-xs font-semibold text-white bg-orange-400 rounded-full"
                    >
                        {matchCount > 0 ? `+${matchCount}` : matchCount}
                    </span>
                }
            </div>
        </button>
    );
}
