import { InputField } from '../components/Fields';
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { authService } from '../services/api/AuthService';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { ErrorAlert } from '../components/Alerts';

export const Login = () => {
    const navigate = useNavigate();

    const schema = yup
        .object({
            login: yup
                .string()
                .required("ce champ est requis."),
            password: yup
                .string()
                .required("ce champ est requis.")
        })
        .required();

    const { register, handleSubmit, formState: { errors } } = useForm({
        resolver: yupResolver(schema),
        mode: "onBlur",
    });

    const [pending, setPending] = useState(false);
    const [error, setError] = useState(null);
    const onSubmit = data => {
        setError(null);
        setPending(true);
        console.log(data);
        authService.login(data.login, data.password)
            .then(r => {
                console.log(r?.data);
                authService.saveToken(r?.data?.token);
                localStorage.setItem('_id', r?.data?._id);
                navigate("/");
            })
            .catch(e => setError(e?.response?.data?.message))
            .finally(() => setPending(false));
    }

    return (
        <div className={"flex flex-col items-center justify-center text-center mx-3"}>
            <img src="logo.png" alt="spart-logo" className="w-52 h-52" />
            {error && <ErrorAlert message={error} />}
            <form className={"flex max-w-md flex-col gap-4 w-full"} onSubmit={handleSubmit(onSubmit)}>
                <InputField id={"login"} label={"Email/pseudo"} type={"text"} required shadow register={register} error={errors?.login} placeholder={"john.doe@domain.com"} />
                <InputField id={"password"} label={"Mot de passe"} type={"password"} placeholder={"•".repeat(8)} required shadow register={register} error={errors?.password} />
                <button className='btn bg-purple-300 text-white hover:bg-purple-400' type={"submit"}>
                    {pending ? <><span className="loading loading-spinner" />En cours...</> : "Se connecter"}
                </button>
                <button className={"text-blue-500 underline"} onClick={() => navigate("/register")}>Pas encore de compte ?</button>
            </form>
        </div>
    );
}
