import { InputField } from '../components/Fields';
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { authService } from '../services/api/AuthService';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { ErrorAlert } from '../components/Alerts';

export const Register = () => {
    const navigate = useNavigate();

    const schema = yup
        .object({
            nom: yup
                .string()
                .required("Le champ nom est requis."),
            prenom: yup
                .string()
                .required("Le champ prenom est requis."),
            email: yup
                .string()
                .required("Le champ email est requis.")
                .min(8, "doit contenir au moins 8 caractéres."),
            password: yup
                .string()
                .required("Le champ mot de passe est requis.")
                .min(8, "Le mot de passe doit contenir au moins 8 caractéres.")
                .matches(/(?=.*[A-Z])/, "doit contenir au moins une lettre majuscule.")
                .matches(/(?=.*[a-z])/, "doit contenir au moins une lettre minuscule.")
                .matches(/(?=.*[0-9])/, "doit contenir au moins un chiffre.")
                .matches(
                    /(?=.*[!@#$%^&*\-_])/,
                    "doit contenir au moins un caractére spécial: !@#$%^&*s-_"
                ),
            repeatPassword: yup
                .string()
                .oneOf([yup.ref('password'), null], 'doit correspondre au mot de passe.'),
        })
        .required();

    const { register, handleSubmit, formState: { errors } } = useForm({
        resolver: yupResolver(schema),
        mode: "onBlur",
    });

    const [pending, setPending] = useState(false);
    const [error, setError] = useState(null);
    const onSubmit = data => {
        setPending(true);
        console.log(data);
        authService.register(data.nom, data.prenom, data.email, data.password)
            .then(r => {
                console.log(r.data);
                authService.saveToken(r.data.token);
                navigate("/profile/creation");
            })
            .catch(e => setError(e.response.data.message))
            .finally(() => setPending(false));
    }

    return (
        <div className={"flex flex-col items-center justify-center mx-3 h-max"}>
            <img src="logo.png" alt="spart-logo" className="w-32 h-32" />
            {error && <ErrorAlert message={error} />}
            <form className={"flex max-w-sm flex-col w-full"} onSubmit={handleSubmit(onSubmit)}>
                <InputField id={"nom"} label={"Nom"} type={"text"} placeholder={"Doe"} register={register} error={errors?.nom} />
                <InputField id={"prenom"} label={"Prénom"} type="text" placeholder={"John"} required register={register} error={errors?.prenom} />
                <InputField id={"email"} label={"Email"} type="email" placeholder={"john.doe.domain.com"} required register={register} error={errors?.email} />
                <InputField id={"password"} label={"Mot de passe"} type={"password"} placeholder={"•".repeat(8)} required register={register} error={errors?.password} />
                <InputField id={"repeatPassword"} label={"Confirmer le mot de passe"} type={"password"} placeholder={"•".repeat(8)} required register={register} error={errors?.repeatPassword} />
                <div className="form-control">
                    <label className="label cursor-pointer justify-start space-x-2">
                    <input type="checkbox" className="checkbox checkbox-primary" />
                        <span className="label-text">J'accepte les <a href={"#"} className={"text-cyan-600 hover:underline dark:text-cyan-500"}> conditions d'utilisation</a></span>
                    </label>
                </div>
                <button className="btn bg-purple-300 text-white hover:bg-purple-400 my-2" type='submit'>
                    { pending ? <><span className="loading loading-spinner" />En cours...</> : "S'inscrire" }
                </button>
                <button className={"link link-primary"} onClick={() => navigate("/login")}>Connexion</button>
            </form>
        </div>
    );
}
