import Rating from "../rating/Rating";
import { useState } from "react";
import { feedbackService } from "../../services/api/FeedbackService";
import { useNavigate } from "react-router-dom";
import { toast } from "sonner";

export const FeedbackFormLayout = () => {
    const [ratingValue, setRatingValue] = useState(0);
    const [message, setMessage] = useState("");
    const navigate = useNavigate();

    const handleMessageChange = (event) => {
        setMessage(event.target.value);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        console.log({ note: ratingValue, message: message });
        feedbackService.storeFeedback({ note: ratingValue, message: message })
            .then(r => {
                console.log(r.data);
                navigate("/");
                toast.success("Merci pour votre avis !");
            })
            .catch(err => console.error(err));
    };

    return (
        <div className="flex flex-col items-center justify-center mx-3 mt-5 space-y-5">
            <h2 className="text-2xl font-bold tracking-tight">Votre avis compte !</h2>
            <form className="" onSubmit={handleSubmit}>
                <div className="mx-auto max-w-xl lg:mr-0 lg:max-w-lg">
                    <div className="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-2">
                        <label htmlFor="rating" className="block text-sm font-semibold leading-6 ">
                            Note
                        </label>
                        <Rating setRatingValue={setRatingValue} />

                        <div className="sm:col-span-2">
                            <label htmlFor="message" className="block text-sm font-semibold leading-6 ">
                                Message
                            </label>
                            <div className="mt-2.5">
                                <textarea
                                    name="message"
                                    id="message"
                                    rows={4}
                                    className="textarea textarea-primary w-full"
                                    defaultValue={''}
                                    onChange={handleMessageChange}
                                />
                            </div>
                        </div>
                    </div>
                    <button className='btn bg-purple-300 text-white hover:bg-purple-400' type={"submit"}>
                        Envoyer
                    </button>
                </div>
            </form>
        </div>
    )
}
