import { Link } from "react-router-dom";

export const Error404 = () => {
    return (
        <div className="p-10 flex flex-col justify-between h-screen w-full">
            <img src="logo.png" alt="spart-logo" className="self-end w-52 h-52" />
            <div className="flex flex-col items-start space-y-3">
                <h1 className="text-7xl">404</h1>
                <p className="text-md">La page que vous cherchez n'existe pas.</p>
                <Link className={"btn btn-primary"} to="/">Retour à l'accueil</Link>
            </div>
        </div>
    );
}
