import axios from "axios";
import { authService } from "./AuthService";

const Axios = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
        "Content-Type": "application/json"
    }
})

Axios.interceptors.request.use(request => {
    if (authService.isLogged()) {
        request.headers.Authorization = `Bearer ${authService.getToken()}`
    }
    return request
})

Axios.interceptors.response.use(function (response) {
    if (response.data.account_state !== undefined) {
        localStorage.setItem("account_state", response.data.account_state)
    }
    return response;
}, function (error) {
    if (error.response.status === 401 && authService.isLogged()) {
        authService.logout()
        window.location = "/login"
    }
    return Promise.reject(error);
});

export default Axios
