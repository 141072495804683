import { useEffect, useState } from "react";
import { CardLayout, NavigationButtons } from "../components/card/CardLayout";
import { UserSportsSlide } from "../components/card/slides/UserSportsSlide";
import { ProfileSlide } from "../components/card/slides/UserProfileSlide";
import { cardsService } from "../services/api/CardsService";
import { toast } from "sonner";

export const Matching = () => {
    const [users, setUsers] = useState([]);

    useEffect(() => {
        cardsService.getCards()
            .then(r => setUsers(r?.data))
            .catch(err => console.log(err));
    }, []);

    return (
        <div className={"flex flex-col items-center justify-center"}>
            <Deck users={users} setUsers={setUsers} />
        </div>
    );
};

const Deck = ({ users, setUsers }) => {
    const [pendingSwipe, setPendingSwipe] = useState(false);

    const onSwipe = (direction) => {
        setPendingSwipe(true);
        console.log(`You swiped to ${direction} on user ${users[0].pseudo}`);
        setTimeout(() => {
            cardsService.sendSwipe(users[0]['_id'], direction)
            .then(r => {
                console.log('Swipe enregistré avec succès', r.data);
                setUsers(users?.slice(1));
                setUser(null);
                setIndexSlide(0);
            })
            .catch(err => {
                console.error('Erreur lors de l\'enregistrement du swipe', err);
                toast.error('Une erreur est survenue lors du swipe');
            })
            .finally(() => setPendingSwipe(false));
        }, 1000);
    }

    const [user, setUser] = useState(null);
    useEffect(() => {
        setUser(users[0]);
    }, [user, users]);

    const [indexSlide, setIndexSlide] = useState(0);

    return (
        <>
            <div className={"relative w-[300px] h-[450px] mb-5"}>
                <StackBackgroundCard>
                    {pendingSwipe && <span className={"loading loading-dots loading-lg"}></span>}
                    {users?.length === 0 && <span className={"text-center text-2xl font-bold italic"}>Plus d'utilisateur disponible</span>}
                </StackBackgroundCard>
                {user && (
                    <CardLayout onSwipe={onSwipe}>
                        <UserSportsSlide sports={user?.sports} />
                        <ProfileSlide
                            pseudo={user?.pseudo}
                            description_personnelle={user?.description_personnelle}
                            ville={user?.ville}
                        />
                    </CardLayout>
                )}
            </div>
            {users?.length > 0 && (
                <NavigationButtons
                    index={indexSlide}
                    setIndex={setIndexSlide}
                    length={2}
                />
            )}
        </>
    );
};

const StackBackgroundCard = ({ children }) => {
    const margins = ["20px 0px 0px 20px", "10px 0px 0px 10px", "0"];

    return (
        margins.map((margin, index) => (
            <div
                key={index}
                className={"absolute bg-gray-200 rounded-xl flex flex-col justify-center items-center h-full w-full shadow-md"}
                style={{ margin }}
            >
                {index === 2 && children}
            </div>
        ))
    );
};
