import Axios from "./ApiAxiosConfiguration";

const getCards = () => {
    return Axios.get('/api/matching/cards')
}

const sendSwipe = (targetUserId, direction) => {
    return Axios.post('/api/matching/swipe', {targetUserId, direction});
};

export const cardsService = {
    getCards,
    sendSwipe,
}
