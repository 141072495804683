import React, { useEffect, useState } from 'react';
import { ChatList } from 'react-chat-elements';
import MessageService from '../../services/api/MessageService';
import { useNavigate } from 'react-router-dom';

const ConversationListComponent = ({ onViewChange }) => {
    const navigate = useNavigate();
    const [conversations, setConversations] = useState([]);
    const userID = localStorage.getItem('_id');

    useEffect(() => {
        fetchConversations();
    }, []);

    const fetchConversations = async () => {
        try {
            const response = await MessageService.getConversation(userID);
            const messages = response.data.messages;
            const destinatorName = response.data.destinatorName;

            const conversationMap = {};
            messages.forEach(message => {
                const otherUserId = message.id_utilisateur_envoyeur !== userID ? message.id_utilisateur_envoyeur : message.id_utilisateur_destinataire;
                if (!conversationMap[otherUserId]) {
                    conversationMap[otherUserId] = {
                        id: otherUserId,
                        avatar: 'https://gravatar.com/avatar/placeholder?s=400&d=mp&r=x', // Avatar par défaut
                        alt: 'Avatar',
                        title: destinatorName, // Utilisez le nom du destinataire
                        subtitle: message.contenu,
                        date: message.date_heure,
                        unread: 1,
                    };
                } else {
                    conversationMap[otherUserId].subtitle = message.contenu;
                    conversationMap[otherUserId].date = message.date_heure;
                    conversationMap[otherUserId].unread++;
                }
            });

            const conversationList = Object.values(conversationMap);
            setConversations(conversationList);
        } catch (error) {
            console.error('Erreur lors de la récupération des conversations :', error);
        }
    };

    const handleConversationClick = (conversationId) => () => {
        onViewChange(conversationId);
        console.log('Conversation clicked:', conversationId);
    };

    return (
        <div>
            {conversations.length === 0 && (
                <div className={"text-center"}>
                    <p className="text-center text-gray-500">Vous n'avez aucune conversation pour le moment.</p>
                    <button onClick={() => navigate('/matching')} className="text-center text-blue-500">Allez swiper pour rencontrer de nouvelles personnes !</button>
                </div>

            )}
            {conversations.map((conversation, index) => (
                <ChatList
                    key={index}
                    id={conversation.id}
                    onClick={handleConversationClick(conversation.id)}
                    dataSource={[
                        {
                            avatar: conversation.avatar,
                            alt: conversation.alt,
                            title: conversation.title,
                            subtitle: conversation.subtitle,
                            date: conversation.date,
                            unread: conversation.unread,
                        },
                    ]}
                />
            ))
            }
        </div>
    );
};

export default ConversationListComponent;
