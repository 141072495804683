import React, { useState } from 'react';
import ConversationList from './ConversationList';
import MessageList from './MessageList';

const ChatContainer = () => {
    const [view, setView] = useState('conversations');
    const [selectedConversationId, setSelectedConversationId] = useState(null);

    const toggleView = (conversationId = null) => {
        setView(view === 'conversations' ? 'messages' : 'conversations');
        console.log('Toggling view. Conversation ID:', conversationId);
        if (conversationId !== null) {
            setSelectedConversationId(conversationId);
        }
    };

    return (
        <div className="w-full h-full">
            {view === 'conversations' ? (
                <div>
                    <h1 className="text-center font-Chewy mb-8">Conversations</h1>
                    <ConversationList onViewChange={toggleView} selectedConversationId={selectedConversationId} />
                </div>
            ) : (
                <MessageList onViewChange={toggleView} selectedConversationId={selectedConversationId} />
            )}
        </div>
    );
};

export default ChatContainer;
