import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import TinderCard from 'react-tinder-card'

export const CardLayout = ({ onSwipe, children }) => {
    return (
        <TinderCard
            className={"absolute w-full h-full"}
            swipeThreshold={100}
            swipeRequirementType={"position"}
            onSwipe={onSwipe}
            preventSwipe={['up', 'down']}
        >
            <div className={"w-full h-full bg-gray-200 rounded-xl flex flex-col justify-center items-center select-none shadow-md"}>
                <div className={"carousel h-full w-72"}>
                    {children?.map((child, index) => (  
                        <div
                            key={index}
                            className={"carousel-item relative w-full h-full"}
                            id={`slide-${index}`}
                        >
                            {child}
                        </div>
                    ))}
                </div>
            </div>
        </TinderCard>
    );
}

export const NavigationButtons = ({ index, setIndex, length }) => {

    const handleLeftClick = () => {
        console.log('left click');
        setIndex(index === 0 ? 0 : index - 1);
    }

    const handleRightClick = () => {
        console.log('right click');
        setIndex(index === length - 1 ? length - 1 : index + 1);
    }

    return (
        <div className={"flex justify-around items-center w-full py-5 px-10"}>
            <a
                className={`btn btn-circle btn-outline ${index === 0 && "btn-disabled"}`}
                href={`#slide-${index}`}
                onClick={handleLeftClick}
            >
                <FaArrowLeft />
            </a>
            <a
                className={`btn btn-circle btn-outline ${index === length - 1 && "btn-disabled"}`}
                href={`#slide-${index}`}
                onClick={handleRightClick}
            >
                <FaArrowRight />
            </a>
        </div>
    )
}
