export const UserSportsSlide = ({ sports }) => {
    return (
        <div className={"w-full flex flex-col justify-center items-center space-y-10"}>
            {sports?.map((sport, index) => {
                return (
                    <div key={index} className={"flex flex-col justify-center items-center"}>
                        <h1 className={"text-4xl font-bold font-Chewy text-texte1"}>{sport?.name || "Inconnu"}</h1>
                        <h3 className={"text-xl italic text-texte2"}>Niveau</h3>
                        <progress className={"progress progress-primary w-56"} value={sport?.level || null} max={100}></progress>
                        <p className="font-semibold my-1">Rythme : {sport?.frequency ?? 0} fois par semaine</p>
                    </div>
                );
            })}
            {(!sports || sports?.length === 0) && <h1 className={"text-4xl font-bold font-Chewy text-texte1"}>Aucun sport</h1>}
        </div>
    );
}
