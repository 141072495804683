import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { matchService } from "../services/api/MatchService";
import { FaCheck, FaTimes, FaFlagCheckered, FaBan, FaEnvelope } from "react-icons/fa";
import { Link } from "react-router-dom";
import { format } from 'date-fns';

export const MatchList = () => {
    const [matches, setMatch] = useState();
    useEffect(() => {
        if (!matches) {
            matchService.getMatch()
                .then(r => setMatch(r?.data))
                .catch(e => console.error("Erreur lors de la récupération des matchs en attente", e));
        }
    }, [matches]);

    const handleMatchUpdate = (match) => {
        console.log("update", match);
        setMatch(matches.map(m => m._id === match._id ? { ...m, ...match } : m));
    };

    return (
        <div>
            <h1 className={"text-2xl font-bold mb-4 font-Chewy"}>Mes matchs</h1>
            {!matches && <span className={"loading loading-spinner loading-lg"}></span>}
            {matches && matches.length === 0 && (
                <div className={"flex flex-col items-center"}>
                    <p className={"text-center text-gray-500"}>Aucun match pour le moment.</p>
                    <Link to={"/matching"} className={"text-center text-blue-300 hover:text-blue-500"}>Aller swiper !</Link>
                </div>
            )}
            {matches &&
                <ul>
                    {matches.map((match, index) => (
                        <MatchCard key={index} match={match} onMatchUpdate={handleMatchUpdate} />
                    ))}
                </ul>
            }
        </div>
    );
}

const MatchCard = ({ match, onMatchUpdate }) => {
    return (
        <li className={"p-4 mb-2 mx-auto w-full"}>
            <div className={"flex flex-col items-start justify-between bg-white shadow rounded-lg p-4 mb-2"}>
                <div className="mb-4">
                    <p className="font-bold">
                        {match?.match_user?.pseudo}
                    </p>
                    <p className="font-medium">
                        {match?.sport?.nom}
                    </p>
                    <p className="text-sm">
                        <span className="font-medium">{match?.defi?.nom_defi}</span> : {match?.defi?.description}
                    </p>
                    <p className="text-sm text-gray-500">
                        Match du : {format(new Date(match?.date_proposition), 'dd/MM/yyyy')}
                    </p>
                </div>
                <MatchActions match={match} onMatchUpdate={onMatchUpdate} />
            </div>
        </li>
    );
};

const MatchActions = ({ match, onMatchUpdate }) => {
    const navigate = useNavigate();

    const handleAccept = () => {
        const state = match?.match_user?.accept ? "Accepté" : "Confirmation";
        matchService.putAcceptedMatch(match._id)
            .then(() => onMatchUpdate({ ...match, etat: state, log_user: { ...match.log_user, accept: true } }))
    };

    const handleDecline = () => {
        matchService.putDeclinedMatch(match._id)
            .then(() => onMatchUpdate({ ...match, etat: "Refusé" }))
    };

    const handleCompleted = () => {
        matchService.putCompletedMatch(match._id)
            .then(() => onMatchUpdate({ ...match, etat: "Terminé" }))
    };

    const handleCancelled = () => {
        matchService.putCancelledMatch(match._id)
            .then(() => onMatchUpdate({ ...match, etat: "Abandonné" }))
    };

    return (
        <div className="flex flex-col items-center w-full">
            {(match?.etat === "Envoyé") && (
                <div className="flex justify-center space-x-2 mb-2">
                    <button onClick={handleAccept} className="inline-flex items-center px-3 py-1.5 border border-transparent text-xs font-medium rounded shadow-sm bg-purple-300 text-white hover:bg-purple-400"><FaCheck /></button>
                    <button onClick={handleDecline} className="inline-flex items-center px-3 py-1.5 border border-transparent text-xs font-medium rounded shadow-sm text-white bg-orange-400 hover:bg-orange-500"><FaTimes /></button>
                </div>
            )}
            {match?.etat === "Confirmation" && (
                (match?.log_user?.accept ? (
                    <p className="text-sm mb-2">Vous avez accepté ce match. Attendez la confirmation de votre futur partenaire.</p>
                ) : (
                    <div className="flex flex-col items-center w-full">
                        <div className="flex justify-center space-x-2 mb-2">
                            <button onClick={handleAccept} className="inline-flex items-center px-3 py-1.5 border border-transparent text-xs font-medium rounded shadow-sm bg-purple-300 text-white hover:bg-purple-400"><FaCheck /></button>
                            <button onClick={handleDecline} className="inline-flex items-center px-3 py-1.5 border border-transparent text-xs font-medium rounded shadow-sm text-white bg-orange-400 hover:bg-orange-500"><FaTimes /></button>
                        </div>
                        <p className="text-sm mb-2">{match?.match_user?.pseudo} a accepté ce match.</p>
                    </div>
                ))
            )}
            {match?.etat === "Accepté" && (
                <>
                    <button onClick={() => navigate("/chat")} className="inline-flex items-center px-3 py-1.5 border border-transparent text-xs font-medium rounded shadow-sm bg-purple-300 text-white hover:bg-purple-400 m-2">
                        <FaEnvelope className="mr-2" /> Envoyer un message
                    </button>
                    <div className="flex justify-center space-x-2 mb-2">
                        <button onClick={handleCompleted} className="inline-flex items-center px-3 py-1.5 border border-transparent text-xs font-medium rounded shadow-sm bg-purple-300 text-white hover:bg-purple-400">
                            <FaFlagCheckered className="mr-2" /> Activité terminée
                        </button>
                        <button onClick={handleCancelled} className="inline-flex items-center px-3 py-1.5 border border-transparent text-xs font-medium rounded shadow-sm text-white bg-orange-400 hover:bg-orange-500">
                            <FaBan className="mr-2" /> Abandonnée
                        </button>
                    </div>
                </>
            )}
            {match?.etat === "Refusé" && (
                <p className="text-sm mb-2">Oh non, ce défi a été refusé.</p>
            )}
            {match?.etat === "Abandonné" && (
                <p className="text-sm mb-2">Mince, ce défi a été abandonné.</p>
            )}
            {match.etat === "Terminé" && (
                <>
                    <p className="text-sm font-medium mb-2">Passez au défi suivant !</p>
                    <button onClick={() => alert('Fonctionnalité bientôt disponible ! Merci de votre compréhension :)')} className="inline-flex items-center px-3 py-1.5 border border-transparent text-xs font-medium rounded shadow-sm bg-purple-300 text-white hover:bg-purple-400 m-2">
                        C'est parti !
                    </button>
                </>
            )}
        </div>
    );
};
