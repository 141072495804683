import { useState } from 'react';

const Rating = ({ setRatingValue }) => { // Ajoutez setRatingValue ici
    const [rating, setRating] = useState(0);
    const [hover, setHover] = useState(0);

    const handleClick = (ratingValue) => {
        setRating(ratingValue);
        setRatingValue(ratingValue); // Maintenant setRatingValue est défini
    };

    return (
        <div className="flex">
            {[...Array(5)].map((star, i) => {
                const ratingValue = i + 1;

                return (
                    <label key={i}>
                        <input
                            type="radio"
                            name="rating"
                            value={ratingValue}
                            onClick={() => handleClick(ratingValue)}
                            className="sr-only"
                        />
                        <svg
                            className={`w-12 h-12 cursor-pointer transition duration-200 ease-in-out ${ratingValue <= (hover || rating) ? 'text-yellow-500' : 'text-gray-300'}`}
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            onMouseEnter={() => setHover(ratingValue)}
                            onMouseLeave={() => setHover(0)}
                        >
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 15.667l-4.667 2.333 1.2-4.733-4-3.2 5.067-.467L12 2l1.4 5.6 5.067.467-4 3.2 1.2 4.733L12 15.667z" />
                        </svg>
                    </label>
                );
            })}
        </div>
    );
};

export default Rating;