import { useNavigate } from 'react-router-dom';
import { authService } from '../services/api/AuthService';
import { FaCalendarAlt, FaComment } from "react-icons/fa";
import { Tooltip } from 'react-tooltip'

export const Home = () => {
    const navigate = useNavigate();

    return (
        <div className={"flex flex-col items-center justify-center h-full space-y-5 text-center mx-8"}>
            <img src="logo.png" alt="spart-logo" className="w-32 h-32" />
            <p className="m-6 p-2">Rencontrez les partenaires parfaits pour vivre vos passions sportives.</p>
            {!authService.isLogged() &&
                <button className='btn bg-purple-300 text-white hover:bg-purple-400 self-end ml-auto mr-auto' onClick={() => navigate("/login")}>Se connecter</button>
            }
            <div className="flex flex-row space-x-3">
                <button className='btn bg-purple-300 text-white hover:bg-purple-400' onClick={() => navigate("/event")}><FaCalendarAlt /> Évènements</button>
                <div>
                    <button
                        data-tooltip-id="tooltip-feedback"
                        data-tooltip-content="Faite nous part de vos suggestions."
                        data-tooltip-place="bottom"
                        className='btn bg-purple-300 text-white hover:bg-purple-400'
                        onClick={() => navigate('/feedback')}><FaComment /> Feedback
                    </button>
                    <Tooltip id="tooltip-feedback"/>
                </div>
            </div>
        </div>
    );
}
