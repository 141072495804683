import Axios from "./ApiAxiosConfiguration";
import {jwtDecode} from "jwt-decode";
import EventEmitter from "../../pages/events/EventEmitter";

const login = (login, password) => {
    return Axios.post('/api/login', { login, password })
        .then(response => {
            saveToken(response.data.token)
            EventEmitter.emit('authStateChanged', true)
            return response
        })
}

const register = (nom, prenom, email, password) => {
    return Axios.post('/api/register', { nom, prenom, email, password })
}

const createProfile = (sexe, pseudo, description_personnelle, ville) => {
    return Axios.post('/api/profile', { sexe, pseudo, description_personnelle, ville })
}

const selectSportsPreferences = (sports) => {
    return Axios.post('/api/profile/sports', { sports })
}

const saveToken = (token) => {
    localStorage.setItem('token', token)
    EventEmitter.emit('authStateChanged', true)
}

const logout = () => {
    localStorage.removeItem('token')
    EventEmitter.emit('authStateChanged', false)
    window.location = "/"
}

const getToken = () => {
    return localStorage.getItem('token')
}

const isLogged = () => {
    const token = localStorage.getItem('token')
    const accountState = localStorage.getItem('account_state')
    return !!token && !isTokenExpired(token) && accountState
}

const isTokenExpired = (token) => {
    try {
        const decoded = jwtDecode(token);
        return decoded.exp < Date.now() / 1000;
    }
    catch (err) {
        return true;
    }
}

export const authService = {
    login,
    register,
    saveToken,
    logout,
    getToken,
    isLogged,
    isTokenExpired,
    createProfile,
    selectSportsPreferences,
}
