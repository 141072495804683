class EventEmitter {
    constructor() {
        this.events = {};
    }

    on(event, listener) {
        if (this.events[event]) {
            this.events[event].push(listener);
        } else {
            this.events[event] = [listener];
        }
    }

    emit(event, payload) {
        if (this.events[event]) {
            this.events[event].forEach(listener => listener(payload));
        }
    }

    off(event, listener) {
        if (this.events[event]) {
            this.events[event] = this.events[event].filter(l => l !== listener);
        }
    }
}

export default new EventEmitter();