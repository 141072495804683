import Axios from "./ApiAxiosConfiguration";

const getConversation = (id_utilisateur_connected) => {
    return Axios.get('/api/conversation', { params: { id_utilisateur_connected } });
}

const getMessage = (id_utilisateur_destinataire) => {
    return Axios.get('/api/message', { params: { id_utilisateur_destinataire } });
}


const sendMessage = (message, id_destinataire) => {
    return Axios.post('/api/message/send', { message, id_destinataire });
};

/*
const markAsRead = (messageId) => {
    return Axios.patch('/api/message/read', { messageId });
}

 */

export default {
    getConversation,
    getMessage,
    sendMessage,
    //markAsRead
};