import { useForm } from "react-hook-form";
import { useEffect, useState } from 'react';
import { ComboboxField, InputField, TextAreaField } from '../../components/Fields';
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useNavigate } from "react-router-dom";
import { sportService } from "../../services/api/SportService";
import { eventService } from "../../services/api/EventService";

export const EventForm = () => {
    const navigate = useNavigate();

    const schema = yup
        .object({
            sport: yup
                .string()
                .required("Le champ sport est requis."),
            name: yup
                .string()
                .required("Le champ nom est requis."),
            description: yup
                .string()
                .required("Le champ description est requis."),
            date: yup
                .date()
                .required("Le champ date est requis."),
            hour: yup
                .string()
                .required("Le champ heure est requis."),
            location: yup
                .string()
                .required("Le champ lieu est requis."),
            max_participants: yup
                .number()
                .integer()
                .positive()
                .required("Le champ nombre de participants est requis.")
                .min(1, "Le nombre de participants doit être supérieur à 0."),
        })
        .required();

    const { register, handleSubmit, formState: { errors }, setValue } = useForm({
        mode: "onChange",
        resolver: yupResolver(schema),
    });

    const [pending, setPending] = useState(false);
    const onSubmit = data => {
        setPending(true);
        console.log(data);
        eventService.createEvent(data.sport, data.name, data.description, data.date, data.hour, data.location, data.max_participants)
            .then(r => navigate("/event"))
            .catch(err => console.log(err))
            .finally(() => setPending(false));
    }

    const [sports, setSports] = useState(null);
    useEffect(() => {
        if (!sports) {
            sportService.getSports()
                .then(r => setSports(r.data.sports))
                .catch(err => console.error(err));
        }
    }, [sports]);

    const [sportsToDisplay, setSportsToDisplay] = useState(null);
    useEffect(() => {
        if (!sportsToDisplay && sports) {
            setSportsToDisplay(sports);
        }
    }, [sports, sportsToDisplay]);
    const onChangeComboboxSport = (e) => {
        if (e.target.value) {
            setSportsToDisplay(sports.filter(sport => sport.nom.toLowerCase().includes(e.target.value.toLowerCase())));
        } else {
            setSportsToDisplay(sports);
        }
    }

    return (
        <>
            <h1>Nouvel évènement</h1>
            <form className={"flex flex-col space-y-2"} onSubmit={handleSubmit(onSubmit)}>
                <ComboboxField id={"sport"} name={"sport"} label={"Sport"} options={sportsToDisplay} onChange={onChangeComboboxSport} required setValue={setValue} error={errors.sport} dataKey={"_id"} dataValue={"nom"} />
                <InputField id={"name"} label={"Nom de l'événement"} type={"text"} placeholder={"exemple: Sortie vélo"} required register={register} error={errors.name} />
                <TextAreaField id={"description"} label={"Description"} placeholder={"Description de l'événement"} required={true} rows={3} register={register} error={errors.description} />
                <InputField id={"date"} label={"Date"} type={"date"} required register={register} error={errors.date} />
                <InputField id={"hour"} label={"Heure"} type={"time"} required register={register} error={errors.hour} />
                <InputField id={"location"} label={"Lieu"} type={"text"} placeholder={"Lieu de l'événement"} required register={register} error={errors.location} />
                <InputField id={"max_participants"} label={"Nombre de participants"} type={"number"} required register={register} error={errors.max_participants} min={1} />
                <button className="btn bg-purple-300 text-white hover:bg-purple-400">
                    {pending ? <><span className="loading loading-spinner" />En cours...</> : "Enregistrer"}
                </button>
            </form>
        </>
    );
}
