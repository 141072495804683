import Axios from "./ApiAxiosConfiguration";

const getNbMatch = () => {
    return Axios.get('/api/matching/nbpending');
}

const getMatch = () => {
    return Axios.get('/api/matching/pendingmatches');
}

const putAcceptedMatch = (id) => {
    return Axios.post('/api/matching/acceptedmatch', { matchId: id });
}

const putDeclinedMatch = (id) => {
    return Axios.post('/api/matching/declinedmatch', { matchId: id });
}

const putCompletedMatch = (id) => {
    return Axios.post('/api/matching/completed', { matchId: id });
}

const putCancelledMatch = (id) => {
    return Axios.post('/api/matching/cancelled', { matchId: id });
}

export const matchService = {
    getNbMatch,
    getMatch,
    putAcceptedMatch,
    putDeclinedMatch,
    putCompletedMatch,
    putCancelledMatch
}