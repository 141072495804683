import Axios from "./ApiAxiosConfiguration";

const getEvents = () => {
    return Axios.get('/api/event');
}

const createEvent = (sport, title, description, date, hour, location, max_participants) => {
    return Axios.post('/api/event', { sport, title, description, date, hour, location, max_participants });
}

const joinEvent = (id) => {
    return Axios.post(`/api/event/${id}/join`);
}

export const eventService = {
    getEvents,
    createEvent,
    joinEvent,
}
