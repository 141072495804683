import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Home } from "./pages/Home";
import { Register } from "./pages/Register";
import { ProfileCreation } from "./pages/ProfileCreation";
import { Login } from "./pages/Login";
import { ProfileSelection } from "./pages/ProfileSportsSelection";
import { AuthGuard } from "./services/AuthGuard";
import { Error404 } from "./pages/Error404";
import { Matching } from "./pages/Matching";
import { MainLayout } from "./components/layout/MainLayout";
import { MatchList } from "./pages/MatchList";
import {FeedbackFormLayout} from "./components/layout/FeedbackFormLayout";
import { Events } from "./pages/events/Events";
import { EventForm } from "./pages/events/EventForm";
import ChatContainer from "./pages/chat/ChatContainer";

export const Routing = () => {
    return (
        <BrowserRouter>
            <Routes>
                {/* Public routes */}
                <Route element={<MainLayout />}>
                    <Route path="register" element={<Register />} />
                    <Route path="login" element={<Login />} />

                    <Route path="/" element={<Home />} />
                    <Route path="event" element={<Events />} />

                    {/* Private routes */}
                    <Route path="*" element={
                        <AuthGuard>
                            <Routes>
                                <Route path="profile/creation" element={<ProfileCreation />} />
                                <Route path="profile/sports_selection" element={<ProfileSelection />} />
                                <Route path="matching" element={<Matching />} />
                                <Route path="mymatch" element={<MatchList />} />
                                <Route path="chat" element={<ChatContainer />} />
                                <Route path="feedback" element={<FeedbackFormLayout />} />
                                <Route path="event/create" element={<EventForm />} />
                            </Routes>
                        </AuthGuard>
                    } />
                </Route>
                <Route path="*" element={<Error404 />} />
            </Routes>
        </BrowserRouter>
    );
}
