import { Combobox, Transition } from '@headlessui/react'
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid'
import { Fragment } from 'react';

export const InputField = ({ id, label, type, placeholder, required, register, error }) => {
    return (
        <label className="form-control">
            <span className="label">{label}</span>
            <input id={id} type={type} placeholder={placeholder} required={required} {...register(id)} className="input input-bordered" />
            <p className={"text-sm italic text-red-500"}>{error?.message}</p>
        </label>
    );
}

export const RadioField = ({ legend, name, options, register, error }) => {
    return (
        <div>
            <fieldset className="flex max-w-md gap-4">
                <legend>{legend}</legend>
                {options.map((option, index) => (
                    <div className="form-control" key={index}>
                        <label className="label cursor-pointer space-x-2">
                            <input type="radio" className="radio radio-primary" id={option} name={name} value={option} {...register(name)} />
                            <span className="label-text">{option}</span>
                        </label>
                    </div>
                ))}
            </fieldset>
            <p className={"text-sm italic text-red-500"}>{error?.message}</p>
        </div>
    );
}

export const TextAreaField = ({ id, label, placeholder, required, rows, register, error }) => {
    return (
        <div className="">
            <div className="block">
                <label htmlFor={id} className="label">{label}</label>
            </div>
            <textarea className="textarea textarea-primary w-full" id={id} placeholder={placeholder} required={required} rows={rows} {...register(id)} />
            <p className={"text-sm italic text-red-500"}>{error?.message}</p>
        </div>
    )
}

export const FileInputField = ({ id, className, src }) => {
    return (
        <div className={className}>
            <label htmlFor={id}>
                <img className={"h-16 w-16 object-cover rounded-full"} src={src} alt={"profil"} />
            </label>
            <input type="file" id={id} className={"hidden"} />
        </div>
    )
}

export const ComboboxField = ({ name, label, onChange, selected, error, setValue, options, dataKey, dataValue }) => {
    return (
        <label className="form-control">
            <span className="label">{label}</span>
            <Combobox value={selected} id={name} name={name} onChange={e => setValue(name, e)}>
                <div className="relative z-50">
                    <div className="relative w-full cursor-default overflow-hidden rounded-lg bg-white text-left shadow-md focus:outline-none focus-visible:ring-2 focus-visible:ring-white/75 focus-visible:ring-offset-2 focus-visible:ring-offset-teal-300 sm:text-sm">
                        <Combobox.Input
                            className="input input-bordered w-full"
                            displayValue={selected}
                            onChange={onChange}
                            autoComplete="off"
                        />
                        <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
                            <ChevronUpDownIcon
                                className="h-5 w-5 text-gray-400"
                                aria-hidden="true"
                            />
                        </Combobox.Button>
                    </div>
                    <Transition
                        as={Fragment}
                        leave="transition ease-in duration-100"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Combobox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm">
                            {options?.map((option) => (
                                    <Combobox.Option
                                        key={option[dataKey]}
                                        className={({ active }) => `relative cursor-default select-none py-2 pl-10 pr-4 ${active ? 'bg-teal-600 text-white' : 'text-gray-900'}`}
                                        value={option[dataValue]}
                                    >
                                        {({ selected, active }) => (
                                            <>
                                                <span className={`block truncate ${selected ? 'font-medium' : 'font-normal'}`}>
                                                    {option[dataValue]}
                                                </span>
                                                {selected ? (
                                                    <span className={`absolute inset-y-0 left-0 flex items-center pl-3 ${active ? 'text-white' : 'text-teal-600'}`}>
                                                        <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                                    </span>
                                                ) : null}
                                            </>
                                        )}
                                    </Combobox.Option>
                                ))
                            }
                        </Combobox.Options>
                    </Transition>
                </div>
            </Combobox>
            <p className={"text-sm italic text-red-500"}>{error?.message}</p>
        </label>
    )
}
