import React, { useState, useEffect } from 'react';
import { useSwipeable } from 'react-swipeable';
import { Input, Avatar } from 'react-chat-elements';
import { FaArrowLeft, FaPaperPlane } from 'react-icons/fa';
import MessageService from '../../services/api/MessageService';

const MessageList = ({ onViewChange, selectedConversationId }) => {
    const [messages, setMessages] = useState([]);
    const [messageContent, setMessageContent] = useState('');

    useEffect(() => {
        if (selectedConversationId) {
            fetchMessages(selectedConversationId);
        }
    }, [selectedConversationId]);

    const fetchMessages = async (conversationId) => {
        try {
            const response = await MessageService.getMessage(conversationId);
            setMessages(response.data);
        } catch (error) {
            console.error('Erreur lors de la récupération des messages :', error);
        }
    };
    const recipientName = messages.find(message => !message.isSentByCurrentUser)?.recipientName || 'Inconnu';

    console.log('Messages:', messages);
    const swipeHandlers = useSwipeable({
        onSwipedRight: () => console.log('Swiped right!'),
    });

    const currentUserId = localStorage.getItem('_id');
    const isMessageSentByCurrentUser = (messageSenderId) => {
        return messageSenderId === currentUserId;
    };

    const handleInputChange = (e) => {
        setMessageContent(e.target.value);
    };

    const sendMessage = () => {
        if (!messageContent.trim()) return;
        console.log("Envoi du message:", messageContent, "dans la conversation:", selectedConversationId);
        MessageService.sendMessage(messageContent, selectedConversationId)
            .then(() => {
                setMessageContent('');
                fetchMessages(selectedConversationId);
            })
            .catch(error => {
                console.error("Erreur lors de l'envoi du message:", error);
            });
    };

    return (
        <div {...swipeHandlers} className="flex flex-col h-full p-2">
            <div className="flex items-center mb-6 pl-4">
                <button onClick={onViewChange} className="p-2 mr-4 hover:bg-gray-200 rounded-full">
                    <FaArrowLeft />
                </button>
                <Avatar src='https://gravatar.com/avatar/placeholder?s=400&d=mp&r=x' alt="Avatar" size="large" className="rounded-full"/>
                <span className="ml-2 font-bold">{recipientName}</span>
            </div>
            <div className="flex flex-col flex-grow overflow-auto">
                <ul>
                    {messages.map((message) => (
                        <li key={message._id} className={`message sender`}>
                            <div className={`chat-message ${isMessageSentByCurrentUser(message.id_utilisateur_envoyeur) ? "chat-end" : "chat-start"}`}>
                                <div className="flex items-end">
                                    <div className={`flex flex-col space-y-2 text-xs max-w-xs mx-2 ${isMessageSentByCurrentUser(message.id_utilisateur_envoyeur) ? "items-end" : "items-start"}`}>
                                        <div>
                                            <span className={`px-4 py-2 rounded-lg inline-block ${isMessageSentByCurrentUser(message.id_utilisateur_envoyeur) ? "bg-blue-500 text-white" : "bg-gray-300 text-black"}`}>
                                                {message.contenu}
                                            </span>
                                        </div>
                                    </div>
                                    <img src="https://gravatar.com/avatar/placeholder?s=400&d=mp&r=x" alt="Photo" className="w-6 h-6 rounded-full" />
                                </div>
                                <div className={`text-xs ${isMessageSentByCurrentUser(message.id_utilisateur_envoyeur) ? "text-right" : "text-left"}`}>
                                    {new Date(message.date_heure).toLocaleString('fr-FR', { year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit' })}
                                </div>
                            </div>
                        </li>
                    ))}
                </ul>
            </div>
            <div className="p-2">
                <Input
                    className="border-accent-2 border-2 rounded-full p-2"
                    placeholder="Type here..."
                    multiline={true}
                    value={messageContent}
                    onChange={handleInputChange}
                    rightButtons={
                        <button className="rounded-full bg-gray-200 p-2 hover:text-primary" onClick={sendMessage}>
                            <FaPaperPlane className="hover:text-primary"/>
                        </button>
                    }
                />
            </div>
        </div>
    );
};

export default MessageList;
