import {authService} from "../../services/api/AuthService";

export const LogoutButton = () => {

    return (
        <button data-tooltip-target="tooltip-home"
                type="button"
                className="inline-flex flex-col items-center justify-center px-5 rounded-s-full hover:bg-gray-50 dark:hover:bg-gray-800 group"
                data-tooltip-id="tooltip-home"
                data-tooltip-content="Retour à l'acceuil"
                data-tooltip-place="right"
                onClick={() => authService.logout()}
        >
            <svg className="w-5 h-5 text-white group-hover:text-blue-600 dark:group-hover:text-blue-500 ml-auto mr-auto mt-auto mb-auto"
                 aria-hidden="true" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                <path
                    d="M502.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-128-128c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L402.7 224 192 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l210.7 0-73.4 73.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l128-128zM160 96c17.7 0 32-14.3 32-32s-14.3-32-32-32L96 32C43 32 0 75 0 128L0 384c0 53 43 96 96 96l64 0c17.7 0 32-14.3 32-32s-14.3-32-32-32l-64 0c-17.7 0-32-14.3-32-32l0-256c0-17.7 14.3-32 32-32l64 0z"/>
            </svg>
            <span className="sr-only">Logout</span>
        </button>
    );
}
