import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { sportService } from '../services/api/SportService';
import { IoIosClose } from "react-icons/io";
import { Toaster, toast } from 'sonner'
import { authService } from '../services/api/AuthService';


export const ProfileSelection = () => {
    const navigate = useNavigate();

    // Retrieves sports list 
    const [sports, setSports] = useState();
    const [categories, setCategories] = useState();
    useEffect(() => {
        if (!sports) {
            sportService.getSports()
                .then(r => setSports(r?.data?.sports))
                .catch(() => setSports([]))
        }
    }, [sports]);

    useEffect(() => {
        if (sports && !categories) {
            setCategories(Array.from(new Set(sports.map(sport => sport.categorie))));
        }
    }, [sports, categories]);

    const [pending, setPending] = useState(false);
    const onSubmit = data => {
        setPending(true);
    }

    const [selectSports, setSelectedSports] = useState([]);

    const steps = [
        {
            component: <SportSelection
                sports={sports}
                categories={categories}
                selectSports={selectSports}
                setSelectedSports={setSelectedSports}
            />,
            submitButton: {
                label: "Suivant",
                onSubmit: () => {
                    if (selectSports.length === 0) {
                        toast.error('Veuillez sélectionner au moins un sport');
                        return;
                    }
                    setCurrentStep(currentStep + 1);
                },
            },
        },
        {
            component: <SportInterest
                selectSports={selectSports}
                setSelectedSports={setSelectedSports}
            />,
            submitButton: {
                label: "Enregistrer",
                onSubmit: () => {
                    console.log(selectSports);
                    setPending(true);
                    authService.selectSportsPreferences(selectSports)
                        .then(() => {
                            navigate('/');
                            toast.success('Vos préférences sportives ont été enregistrées');
                        })
                        .catch(() => toast.error('Une erreur est survenue lors de l\'enregistrement de vos préférences sportives'))
                        .finally(() => setPending(false));
                },
            },
        }
    ];
    const [currentStep, setCurrentStep] = useState(0);

    return (
        <>
            <div className={"flex flex-col justify-center items-center mx-8 my-3 space-y-2 "}>
                {steps[currentStep].component}

                <button className={"btn btn-primary"} onClick={steps[currentStep].submitButton.onSubmit}>
                    {pending ? <><span className="loading loading-spinner" />En cours...</> : steps[currentStep].submitButton.label}
                </button>
            </div>
        </>
    );
}

const SportSelection = ({ sports, categories, selectSports, setSelectedSports }) => {
    const handleSelect = (e) => {
        const sport = e.target.value;
        if (selectSports.length >= 3 && !selectSports.some(s => s.name === sport)) {
            return;
        }

        if (e.target.checked) {
            setSelectedSports([...selectSports, { name: sport }]);
            console.log(sport + " selected");
        } else {
            setSelectedSports(selectSports.filter(s => s.name !== sport));
            console.log(sport + " unselected");
        }
    }

    return (
        <div className={"flex flex-col justify-center items-center"}>
            <h1 className={"text-4xl font-bold"}>Séléctionnez vos sports</h1>
            <p className={"italic"}>Entre 1 et 3 sports</p>

            <div className="join join-vertical w-full my-5">
                {categories?.map((categorie, index) => {
                    return (
                        <div key={index} className={'collapse collapse-arrow join-item border border-base-300'}>
                            <input type="radio" name="my-accordion-4" defaultChecked={index === 0} />
                            <div className="collapse-title text-xl font-medium">{categorie}</div>
                            <div className="collapse-content">
                                <ul>
                                    {sports?.filter((sport) => {
                                        return sport?.categorie === categorie;
                                    }).map((sport, index) => {
                                        return (
                                            <li key={index} className={"flex items-center space-x-2 my-2"}>
                                                <input
                                                    id={sport?.nom}
                                                    value={sport?.nom}
                                                    type={"checkbox"}
                                                    className={"checkbox"}
                                                    onChange={handleSelect}
                                                    disabled={selectSports.length >= 3 && !selectSports.some(s => s.name === sport?.nom)}
                                                    checked={selectSports.some(s => s.name === sport?.nom)}
                                                />
                                                <label htmlFor={sport?.nom}>{sport?.nom}</label>
                                            </li>
                                        )
                                    })}
                                </ul>
                            </div>
                        </div>
                    )
                })}
            </div>

            {selectSports.length > 0 &&
                <div className={"flex flex-col items-center space-y-3 p-3"}>
                    <h3 className={"text-2xl font-bold"}>Sports sélectionnés</h3>
                    <ul className={"flex flex-wrap justify-center gap-3"}>
                        {selectSports.map((sport, index) => {
                            return (
                                <li key={index}>
                                    <button className={"btn btn-sm btn-secondary"}
                                        onClick={() => {
                                            setSelectedSports(selectSports.filter(s => s.name !== sport.nom));
                                        }}>
                                        {sport?.name}
                                        <IoIosClose className={"text-2xl"} />
                                    </button>
                                </li>
                            )
                        })}
                    </ul>
                </div>
            }
        </div>
    )
}

const SportInterest = ({ selectSports, setSelectedSports }) => {

    const levels = [
        "Débutant",
        "Intermédiaire",
        "Confirmé",
        "Expert",
        "Pro"
    ];

    useEffect(() => {
        const newSelectSports = selectSports.map(sport => {
            return {
                ...sport,
                level: 0,
                frequency: 1
            }
        });
        setSelectedSports(newSelectSports);
    }, []);

    const handleLevelChange = (e, sport) => {
        const newSelectSports = selectSports.map(s => {
            if (s.name === sport.name) {
                return {
                    ...s,
                    level: parseInt(e.target.value)
                }
            }
            return s;
        });
        setSelectedSports(newSelectSports);
    }

    const handleFrequencyChange = (e, sport) => {
        const newSelectSports = selectSports.map(s => {
            if (s.name === sport.name) {
                return {
                    ...s,
                    frequency: parseInt(e.target.value)
                }
            }
            return s;
        });
        setSelectedSports(newSelectSports);
    }

    return (
        <div>
            <h1 className={"text-2xl font-bold"}>
                Précisez votre niveau d'intérêt pour chacun des sports sélectionnés</h1>
            <div className={"flex flex-col justify-center gap-5 my-5"}>
                {selectSports?.map((sport, index) => {
                    return (
                        <div key={index} className={"flex flex-col items-center space-y-2"}>
                            <h3 className={"text-2xl font-bold"}>{sport.name}</h3>
                            <select className="select select-bordered w-full max-w-xs" onChange={(e) => handleLevelChange(e, sport)} value={sport.level}>
                                {levels.map((level, index) => {
                                    return (
                                        <option key={index} value={index}>{level}</option>
                                    )
                                })}
                            </select>
                            {/* Nombre de fois par semaine */}
                            <div className={"flex items-center space-x-2"}>
                                <label htmlFor="frequency">Nombre de fois par semaine</label>
                                <input 
                                    type="number" 
                                    className="input input-bordered w-14 text-center" 
                                    id="frequency" 
                                    value={sport?.frequency} 
                                    onChange={(e) => handleFrequencyChange(e, sport)} 
                                    min={1}
                                    max={7}
                                />
                            </div>
                        </div>
                    )
                })}
            </div>
        </div>
    );
}
