import { toast } from 'sonner';
import { io } from 'socket.io-client';
import { useEffect, useRef } from 'react';
import { authService } from './api/AuthService';
import {useNavigate} from "react-router-dom";

export const WebSocketConfiguration = ({ children }) => {
    const navigate = useNavigate();
  const socketRef = useRef(null);

  useEffect(() => {
    if (!socketRef.current && authService.isLogged()) {
      socketRef.current = io(process.env.REACT_APP_API_URL);

      socketRef.current.emit('join_room', {
        userId: localStorage.getItem('_id')
      });

      socketRef.current.on('notification', (data) => {
        console.log('received notification:', data);
        const toastOptions = {
          duration: 3000,
          action: data.route ? {
            label: 'Afficher',
            onClick: () => navigate(data.route),
          } : undefined,
        };

        toast.success(data.message, toastOptions);
      });

      return () => {
        if (socketRef.current) {
          socketRef.current.disconnect();
          socketRef.current = null;
        }
      };
    }
  }, []);

  return children;
}
