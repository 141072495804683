import { useNavigate } from "react-router-dom";
import { authService } from "./api/AuthService";
import { useEffect } from "react";
import { WebSocketConfiguration } from "./WebSocketConfiguration";

export const AuthGuard = ({ children }) => {
    const navigate = useNavigate()

    // if user is not logged, redirect to login page
    useEffect(() => {
        if (!authService.isLogged()) {
            navigate('/login')
        }
    }, [navigate])

    // check account_state and redirect
    useEffect(() => {
        const paths = {
            'PROFILE_CREATION': '/profile/creation',
            'SPORTS_SELECTION': '/profile/sports_selection',
        }
        const accountState = localStorage.getItem('account_state');
        if (accountState && paths[accountState]) {
            navigate(paths[accountState])
        } else if (Object.values(paths).includes(window.location.pathname) && accountState === 'null') {
            navigate('/')
        }
    }, [navigate])

    return (
        <WebSocketConfiguration>
            {children}
        </WebSocketConfiguration>
    );
}
