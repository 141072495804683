import { useState, useEffect } from "react";
import { eventService } from "../../services/api/EventService";
import { useNavigate } from "react-router-dom";
import { FaPlus } from "react-icons/fa6";
import { authService } from "../../services/api/AuthService";
import { CiCalendarDate } from "react-icons/ci";
import { FiClock } from "react-icons/fi";
import { FaLocationDot } from "react-icons/fa6";
import { MdGroups2 } from "react-icons/md";
import { toast } from "sonner";

export const Events = () => {
    const navigate = useNavigate();

    const [events, setEvents] = useState();
    useEffect(() => {
        if (!events) {
            eventService.getEvents()
                .then(r => setEvents(r.data))
                .catch(err => console.error(err));
        }
    }, [events]);

    const joinEvent = (id) => {
        eventService.joinEvent(id)
            .then(r => {
                setEvents(events.map(event => {
                    if (event._id === id) {
                        event.is_user_participant = !event.is_user_participant;
                        event.participants_count = event.is_user_participant ? event.participants_count + 1 : event.participants_count - 1;
                    }
                    return event;
                }));
            })
            .catch(err => console.error(err));
    }

    return (
        <div className="flex flex-col space-y-5 h-full w-full p-5 items-center">
            <h1 className="text-2xl">Évènements</h1>
            {!events && <span className="loading loading-spinner loading-lg"></span>}
            {events && events.length === 0 && <p>Aucun événement à venir</p>}
            {events && events.length > 0 && (
                <div className="flex flex-col w-full space-y-3 pb-16">
                    {events.filter(event => event.is_user_participant)
                        .map(event => <EventCard event={event} onJoin={joinEvent} />)
                    }
                    <h2 className="text-2xl font-semibold text-center pt-5">À venir</h2>
                    {events.filter(event => !event.is_user_participant)
                        .map(event => <EventCard event={event} onJoin={joinEvent} />)
                    }
                </div>
            )}
            {authService.isLogged() && (
                <button
                    className={"btn btn-md bg-purple-300 hover:bg-purple-400 self-center fixed bottom-24 text-white rounded-full shadow-xl"}
                    onClick={() => navigate("/event/create")}
                >
                    <FaPlus className={"text-lg"} />
                </button>
            )}
        </div>
    )
}

const EventCard = ({ event, onJoin }) => {
    return (
        <div key={event._id} className="collapse collapse-arrow join-item border border-base-300 rounded-md">
            <input type="radio" name="my-accordion-4" />
            <div className="collapse-title">
                <span className={"font-semibold"}>{event?.title}</span>
                <p className={"text-gray-500"}>{event?.sport}</p>
                <div className="flex items-center space-x-5">
                    <div className="flex items-center space-x-2"><CiCalendarDate /><span>{event?.date}</span></div>
                    <div className="flex items-center space-x-2"><FiClock /><span>{event?.hour}</span></div>
                </div>
            </div>
            <div className={"collapse-content"}>
                <p className={"italic text-gray-500 m-2"}>{event?.description}</p>
                <div className={"flex justify-between items-center"}>
                    <div className={"flex flex-wrap w-full items-center space-x-5 " + (!authService.isLogged() && "justify-between")}>
                        <div className={"flex items-center space-x-2"}><FaLocationDot /><span>{event?.location}</span></div>
                        <div className={"flex items-center space-x-2 " + (event?.participants_count === event?.max_participants && "text-red-500")}>
                            <MdGroups2 /> <span>{event?.participants_count}/{event?.max_participants}</span>
                        </div>
                    </div>
                    {authService.isLogged() && (
                        <button
                            className={"btn btn-sm text-white " + (event?.is_user_participant ? "btn-error" : "btn-success")}
                            disabled={event?.participants_count === event?.max_participants}
                            onClick={() => (event?.participants_count !== event?.max_participants) && onJoin(event?._id)}
                        >
                            {(event?.participants_count === event?.max_participants) ? "Complet" : event?.is_user_participant ? "Quitter" : "Rejoindre"}
                        </button>
                    )}
                </div>
            </div>
        </div>
    )
}
