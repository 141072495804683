import { Outlet } from "react-router-dom";
import {BottomNavigation} from "./BottomNavigation";

export const MainLayout = () => {
    return (
        <div className={"flex flex-col justify-center mt-5"}>
            <div style={{marginBottom: '120px'}}>
                <Outlet/>
            </div>
            <BottomNavigation/>
        </div>
    );
}
