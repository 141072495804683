import { FaMapMarkerAlt } from "react-icons/fa";

export const ProfileSlide = ({ pseudo, description_personnelle, ville }) => {
    return (
        <div className={"w-full flex flex-col justify-center items-center space-y-5"}>
            <span className={"bg-black w-40 h-40 rounded-full"}></span>
            <h1 className={"text-4xl"}>{pseudo ? `@${pseudo}` : "Inconnu"}</h1>
            <p className={"italic m-5 text-center"}>
                {description_personnelle || "Aucune description"}
            </p>
            <div className={"flex items-center space-x-2"}>
                <FaMapMarkerAlt />
                <span>{ville}</span>
            </div>
        </div>
    )
}
