import {HomeButton} from "../button/HomeButton";
import {MyMatchButton} from "../button/MyMatchButton";
import {SearchMatchButton} from "../button/SearchMatchButton";
import {MessagerieButton} from "../button/MessagerieButton";
import {LogoutButton} from "../button/LogoutButton";
import {authService} from "../../services/api/AuthService";
import {LoginButton} from "../button/LoginButton";
import {useEffect, useState} from "react";
import EventEmitter from "../../pages/events/EventEmitter";

export const BottomNavigation = () => {
    const [isLogged, setIsLogged] = useState(authService.isLogged());

    useEffect(() => {
        const handleAuthChange = (isLogged) => {
            setIsLogged(isLogged);
        };

        EventEmitter.on('authStateChanged', handleAuthChange);

        return () => {
            EventEmitter.off('authStateChanged', handleAuthChange);
        };
    }, []);

    return (
        <div className="fixed z-50 w-full h-16 max-w-lg -translate-x-1/2 bg-white border border-gray-200 rounded-full bottom-4 left-1/2 dark:bg-gray-700 dark:border-gray-600">
            <div className="grid h-full max-w-lg grid-cols-5 mx-auto">

                <HomeButton/>
                <div id="tooltip-home" role="tooltip"
                     className="absolute z-10 invisible inline-block px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-gray-900 rounded-lg shadow-sm opacity-0 tooltip dark:bg-gray-700">
                    Home
                    <div className="tooltip-arrow" data-popper-arrow></div>
                </div>


                <MyMatchButton/>
                <div id="tooltip-wallet" role="tooltip"
                     className="absolute z-10 invisible inline-block px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-gray-900 rounded-lg shadow-sm opacity-0 tooltip dark:bg-gray-700">
                    Mes Matchs
                    <div className="tooltip-arrow" data-popper-arrow></div>
                </div>


                <div className="flex items-center justify-center">
                    <SearchMatchButton/>
                </div>
                <div id="tooltip-new" role="tooltip"
                     className="absolute z-10 invisible inline-block px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-gray-900 rounded-lg shadow-sm opacity-0 tooltip dark:bg-gray-700">
                    Trouver un Match
                    <div className="tooltip-arrow" data-popper-arrow></div>
                </div>

                <MessagerieButton/>
                <div id="tooltip-settings" role="tooltip"
                     className="absolute z-10 invisible inline-block px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-gray-900 rounded-lg shadow-sm opacity-0 tooltip dark:bg-gray-700">
                    Settings
                    <div className="tooltip-arrow" data-popper-arrow></div>
                </div>

                {isLogged &&
                    <LogoutButton/>
                }
                {!isLogged &&
                    <LoginButton />
                }

                <div id="tooltip-profile" role="tooltip"
                     className="absolute z-10 invisible inline-block px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-gray-900 rounded-lg shadow-sm opacity-0 tooltip dark:bg-gray-700">
                    Profile
                    <div className="tooltip-arrow" data-popper-arrow></div>
                </div>
            </div>
        </div>

    )
}