import { useForm } from "react-hook-form";
import { useState } from 'react';
import { ComboboxField, InputField, RadioField, TextAreaField } from '../components/Fields';
import { villeService } from '../services/api/VillesService';
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useNavigate } from "react-router-dom";
import { authService } from "../services/api/AuthService";

export const ProfileCreation = () => {
    const navigate = useNavigate();

    const schema = yup
        .object({
            pseudo: yup
                .string()
                .required("Le champ pseudo est requis."),
            sexe: yup
                .string()
                .required("Le champ sexe est requis."),
            description_personnelle: yup
                .string()
                .required("Le champ description personnelle est requis."),
            ville: yup
                .string()
                .required("Le champ ville est requis."),
        })
        .required();

    const { register, handleSubmit, formState: { errors }, setValue } = useForm({
        mode: "onChange",
        resolver: yupResolver(schema),
    });

    const [pending, setPending] = useState(false);
    const onSubmit = data => {
        setPending(true);
        console.log(data);
        authService.createProfile(data.sexe, data.pseudo, data.description_personnelle, data.ville)
            .then(r => navigate("/profile/sports_selection"))
            .catch(err => console.log(err))
    }

    const [villes, setVilles] = useState([])
    const onChangeVille = (e) => {
        villeService.getVilles(e.target.value)
            .then((r) => {
                setVilles(r.data.map(ville => ({
                    ...ville,
                    nomAndCode: `${ville?.nom} ${ville?.codesPostaux[0]}`
                })))
            })
            .catch((err) => console.log(err));
    }

    return (
        <div className={"flex flex-col items-center justify-center mx-3"}>
            <h1>Profil</h1>
            <form className={"flex flex-col space-y-2"} onSubmit={handleSubmit(onSubmit)}>
                <InputField id={"pseudo"} label={"Pseudo"} type={"text"} placeholder={"Pseudo"} required register={register} error={errors.pseudo} />
                <RadioField legend={"Sexe"} name={"sexe"} options={["Homme", "Femme", "Autre"]} register={register} error={errors.sexe} />
                <TextAreaField id={"description_personnelle"} label={"Description personnelle"} placeholder={"Description personnelle"} required={true} rows={4} register={register} error={errors.description_personnelle} />
                <ComboboxField name={"ville"} label={"Ville"} onChange={onChangeVille} options={villes} error={errors?.ville} setValue={setValue} dataKey={"siren"} dataValue={"nomAndCode"} />
                <button className="btn bg-purple-300 text-white hover:bg-purple-400">
                    {pending ? <><span className="loading loading-spinner" />En cours...</> : "Enregistrer"}
                </button>
            </form>
        </div>
    );
}
