import axios from "axios";

const Axios = axios.create({
    baseURL: "https://geo.api.gouv.fr",
})

const getVilles = (search) => {
    return Axios.get("/communes?nom=" + search + "&limit=10")
}

export const villeService = {
    getVilles,
}
